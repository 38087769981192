import {
  ProductUUID,
  ProductClientID,
  ProductSku,
  ProductID,
  FetchProductsFilters,
  ProductCategoryID
} from './productsTypes';

import { TeamNanoID } from '../teams/teamsTypes';
import { MatchResultUUID } from '../matchResults/matchResultsTypes';
import { LifestyleID } from '../lifestyles/lifestylesTypes';

export class ProductCache {
  static indexCacheKey() {
    return 'products';
  }

  static showCacheKey() {
    return 'product';
  }

  static shortInfoCacheKey() {
    return 'product-short-info';
  }

  static categoriesCacheKey() {
    return 'productCategories';
  }

  static indexNameSkuTagsCacheKey(
    nameSkuTags: FetchProductsFilters['nameSkuTags']
  ) {
    return `products-${nameSkuTags}`;
  }

  static elasticSearchCacheKey() {
    return 'products-elastic-search';
  }

  static elasticSearchSuggestCacheKey() {
    return 'products-elastic-search-suggest';
  }

  static createProductDataCacheKey() {
    return 'create-product-data';
  }

  static notEmptyCategoriesCacheKey() {
    return 'notEmptyProductCategories';
  }

  static skuFilteredCacheKey(sku: ProductSku[]) {
    return `productSkuFiltered-${sku.join('-')}`;
  }

  static idsFilteredCacheKey(ids: ProductID[]) {
    return `productIdsFiltered-${ids.join('-')}`;
  }

  static categoriesLibraryCacheKey(clientIdsPart: string) {
    return `productCategoriesLibrary-${clientIdsPart}`;
  }

  static libraryCacheKey(clientId: ProductClientID) {
    return `productsLibrary-${clientId}`;
  }

  static companyLibraryCacheKey(companyNanoId: TeamNanoID) {
    return `company-products-library-${companyNanoId}`;
  }

  static companyLibraryTotalCountCacheKey(companyNanoId: TeamNanoID) {
    return `company-products-library-total-count-${companyNanoId}`;
  }

  static stylesFilterCacheKey() {
    return 'productStylesFilter';
  }

  static stylesLibraryFilterCacheKey(clientIdsPart: string) {
    return `productStylesLibraryFilter-${clientIdsPart}`;
  }

  static materialsFilterCacheKey() {
    return 'productMaterialsFilter';
  }

  static materialsFilterBadgeCacheKey() {
    return 'productMaterialsFilterBadge';
  }

  static materialsLibraryFilterCacheKey(clientIdsPart: string) {
    return `productMaterialsLibraryFilter-${clientIdsPart}`;
  }

  static materialsSelectedFilterCacheKey() {
    return 'productMaterialsSelectedFilter';
  }

  static typesFilterCacheKey() {
    return 'productTypesFilter';
  }

  static typesLibraryFilterCacheKey(clientIdsPart: string) {
    return `productTypesLibraryFilter-${clientIdsPart}`;
  }

  static typesSelectedFilterCacheKey() {
    return 'productTypesSelectedFilter';
  }

  static seasonalFilterCacheKey() {
    return 'productSeasonalFilter';
  }

  static seasonalLibraryFilterCacheKey(clientIdsPart: string) {
    return `productSeasonalLibraryFilter-${clientIdsPart}`;
  }

  static seasonalSelectedFilterCacheKey() {
    return 'productSeasonalSelectedFilter';
  }

  static colorsFilterCacheKey() {
    return 'productColorsFilter';
  }

  static colorsLibraryFilterCacheKey(clientIdsPart: string) {
    return `productColorsLibraryFilter-${clientIdsPart}`;
  }

  static brandsFilterCacheKey() {
    return 'productBrandsFilter';
  }

  static brandsLibraryFilterCacheKey(clientIdsPart: string) {
    return `productBrandsLibraryFilter-${clientIdsPart}`;
  }

  static brandsSelectedFilterCacheKey() {
    return 'productBrandsSelectedFilter';
  }

  static matchResultProductsCacheKey(matchResultUuid: MatchResultUUID) {
    return `match-result-products-cache-key-${matchResultUuid}`;
  }

  static showQueryKey(productUuid: ProductUUID) {
    return ['product', productUuid];
  }

  static productCountQueryKey(id: ProductID) {
    return `product-count-${id}`;
  }

  static indexSelectCacheKey(fieldName: string) {
    return `product-select-${fieldName}`;
  }
  static defaultSelectCacheKey(fieldName: string) {
    return `product-select-default-${fieldName}`;
  }

  static lifestyleAssociatedAsset(lifestyleId: LifestyleID) {
    return `lifestyle-associated-assets-${lifestyleId}`;
  }

  static categoryPreview(productCategoryId: ProductCategoryID) {
    return `category-preview-${productCategoryId}`;
  }

  static productsLastBasePath() {
    return `products-last-base-path`;
  }
}
