import {
  FetchMaterialCategoriesCacheKey,
  FetchMaterialCategoriesFilters
} from '../../materialsTypes';

import { useIndexQuery } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import { FetchCategoriesScopes } from '../../../categories/categoriesTypes';

import {
  FETCH_MATERIAL_CATEGORIES_QUERY,
  FetchMaterialCategoriesQueryResponse
} from '../../queries/fetchMaterialCategories.query';

const defaultInitialFilters = {
  scope: [FetchCategoriesScopes.MATERIAL_CATEGORIES]
};
const initialSort = ['NAME_ASC'];
const options = {
  staleTime: 1000 * 60 * 60
};

const scope = 'categories';

interface MaterialCategoriesOptions {
  cacheKey?: FetchMaterialCategoriesCacheKey;
  addInitialFilters?: FetchMaterialCategoriesFilters;
}

function useMaterialCategories({
  cacheKey,
  addInitialFilters
}: MaterialCategoriesOptions = {}) {
  const initialFilters = addInitialFilters
    ? { ...defaultInitialFilters, ...addInitialFilters }
    : defaultInitialFilters;

  const { items, itemsError, isFetched, isPlaceholderData } =
    useIndexQuery<FetchMaterialCategoriesQueryResponse>({
      query: FETCH_MATERIAL_CATEGORIES_QUERY,
      cacheKey,
      initialFilters,
      initialSort,
      initialLimit: 1000,
      scope,
      options
    });

  return {
    materialCategories: items,
    materialCategoriesErrorMessage: itemsError,
    materialCategoriesFetched: isFetched,
    materialCategoriesIsPlaceholderData: isPlaceholderData
  };
}

export default useMaterialCategories;
