import { gql } from 'graphql-request';

import {
  CategoryID,
  CategoryUUID,
  CategoryName,
  CategoryLocalizedName,
  CategoryParentID,
  CategoryParentUUID,
  CategoryParentName,
  CategoryParentLocalizedName
} from '../../categories/categoriesTypes';

export interface FetchLifestyleCategoriesQueryResponse {
  uuid: CategoryUUID;
  id: CategoryID;
  name: CategoryName;
  localizedName: CategoryLocalizedName;
  parent?: {
    id: CategoryParentID;
    uuid: CategoryParentUUID;
    name: CategoryParentName;
    localizedName: CategoryParentLocalizedName;
  } | null;
}

export const FETCH_LIFESTYLE_CATEGORIES_QUERY = gql`
  query LifestyleCategories(
    $filters: CategoriesFilters
    $sort: [CategoriesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    categories(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        id
        uuid
        name
        localizedName
        parent {
          name
          id
          uuid
          localizedName
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
