import {
  FetchMaterialsPathQueryFilters,
  MaterialClientID,
  MaterialNanoID,
  MaterialsFiltersNavBasePath
} from './materialsTypes';

const basePath = '/materials';
const libraryBasePath = '/library/materials';

export class MaterialPath {
  static index() {
    return basePath;
  }

  static libraryIndex() {
    return libraryBasePath;
  }

  static show(nanoId: MaterialNanoID) {
    return `${basePath}/${nanoId}`;
  }

  static edit(nanoId: MaterialNanoID) {
    return `${basePath}/${nanoId}/edit`;
  }

  static libraryUser(clientId: MaterialClientID) {
    return `${libraryBasePath}/${clientId}`;
  }

  static indexFiltersQuery(filters: FetchMaterialsPathQueryFilters) {
    return `${basePath}?filters=${JSON.stringify(filters)}`;
  }
  static indexFiltersCustomBaseQuery(
    customBasePath: MaterialsFiltersNavBasePath,
    filters: FetchMaterialsPathQueryFilters
  ) {
    return `${customBasePath}?filters=${JSON.stringify(filters)}`;
  }

  static create() {
    return `${basePath}/create`;
  }
}
